import * as ko from "knockout";
import {NewsWidgetViewModel, NewsWidgetViewModelParams} from "./NewsWidgetViewModel";

const component: KnockoutComponentTypes.Config = {
    viewModel: (params: NewsWidgetViewModelParams) => new NewsWidgetViewModel(params),
    template: <string>require('./pinned-news-item.html')
};

export default component;

if (!ko.components.isRegistered('pinned-news-item')) {
    ko.components.register('pinned-news-item', component)
}