import * as ko from "knockout";

export function ensureObservable<T>(value: T | KnockoutObservable<T>): KnockoutObservable<T> {
  if (ko.isObservable(value)) {
    return value;
  } else {
    return ko.observable(value);
  }
}

export default ensureObservable;