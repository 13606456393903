/**
 * This is a modified version of 18nko from
 * https://github.com/leMaik/i18next-ko
 *
 * This has been done to allow passing a full config object and
 * adjusted so it works with a more recent version of i18next.
 */
import * as i18n from 'i18next';
import * as ko from 'knockout';


// noinspection JSUnusedLocalSymbols
const koBindingHandler = {
    init: function(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
            const i = i18nextko._koElements.indexOf(element);
            if (i >= 0) {
                i18nextko._koElements.splice(i, 1);
                i18nextko._koCallbacks.splice(i, 1);
            }
        });
        i18nextko._koElements.push(element);
        i18nextko._koCallbacks.push(ko.bindingHandlers['i18n'].update.bind(undefined, element, valueAccessor, allBindingsAccessor, viewModel, bindingContext));
        koBindingHandler.update(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext);
    },

    update: function(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        const value = ko.toJS(valueAccessor());
        if (typeof value === 'string') {
            element.innerHTML = i18n.t(value);
        } else if (value.key) {
            element.innerHTML = i18n.t(value.key, value.options);
        } else {
            for (const attr in value) {
                // noinspection JSUnfilteredForInLoop
                const options = value[attr];
                let translation;
                if (typeof options === 'string') {
                    translation = i18n.t(options);
                } else {
                    translation = i18n.t(options.key, ko.toJS(options.options));
                }
                if (attr == 'html') {
                    element.innerHTML = translation;
                } else {
                    const div = document.createElement('div');
                    div.innerHTML = translation;
                    // noinspection JSUnfilteredForInLoop
                    element.setAttribute(attr, div.innerText);
                }
            }
        }
    }
};

const i18nextko = {
    _koElements: [],
    _koCallbacks: [],
    _language: ko.observable("en"),

    setLanguage: function(language) {
        i18n.changeLanguage(language);
        i18nextko._language(language);
        i18nextko._koCallbacks.forEach(function(c) {
            return c.call(undefined);
        });
    },

    init: function(config) {

        i18n.init(config);

        ko.bindingHandlers['i18n'] = koBindingHandler;
        i18nextko._language = ko.observable(config.lng);
        i18nextko.setLanguage(config.lng);
    },

    t: function(key: any, options?: any) {
        const args = arguments;
        return ko.computed(function() {
            i18nextko._language(); //to auto-update this computed observable on language changes
            return i18n.t.apply(i18n, args);
        });
    },

    i18n: i18n
};

export default i18nextko;