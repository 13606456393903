import globalState from "../../../global-state";
import {App} from "../../../app";
import {UserDto} from "../../../api/generated";

export function departmentOptions() {
    return App.objectOptions(globalState.appVars.departments);
}

export function positionOptions() {
    return App.objectOptions(globalState.appVars.positions);
}

export function departmentName(key: string) {
    if(key) {
        if (globalState.appVars.departments && globalState.appVars.departments[key]) {
            return globalState.appVars.departments[key].text;
        }
        return key;
    }
    return "";
}

export function userPosition(user:UserDto) {
    if(user.extraProfile && user.extraProfile.position  && globalState.appVars.positions &&
        globalState.appVars.positions[user.extraProfile.position]) {
        return globalState.appVars.departments[user.department].text;
    }
    return "";
}
