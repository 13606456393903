import * as ko from 'knockout';
import ensureObservable from "../util/ensure-observable"

interface TextInputViewModelParams {
    value: any;
    name: string | KnockoutObservable<string>;
    cssClass: string | KnockoutObservable<string>;
    cssPrefix: string | KnockoutObservable<string>;
    i18nPrefix: string | KnockoutObservable<string>;
    inputType: string | KnockoutObservable<string>;
    onFocus: string | KnockoutObservable<string>;
    showLabel: boolean | KnockoutObservable<boolean>;
}

class TextInputViewModel {
    public value: KnockoutObservable<string>;
    public name: KnockoutObservable<string>;
    public cssClass: KnockoutObservable<string>;
    public cssPrefix: KnockoutObservable<string>;
    public i18nPrefix: KnockoutObservable<string>;
    public inputType: KnockoutObservable<string>;
    public onFocus: KnockoutObservable<string>;
    public showLabel: KnockoutObservable<boolean>;

    constructor(private params:TextInputViewModelParams) {
      this.name = ensureObservable(params.name);
      this.value = ensureObservable(params.value);
      this.cssClass = ensureObservable(params.cssClass || "");
      this.cssPrefix = ensureObservable(params.cssPrefix || "form");
      this.inputType = ensureObservable(params.inputType || "text");
      this.i18nPrefix = ensureObservable(params.i18nPrefix);
      this.showLabel = ensureObservable(params.showLabel == false ? false : true);
      this.onFocus = ensureObservable(params.onFocus || "");
    }

    public cssClassField = ko.pureComputed( () =>
        "form-control " + this.cssPrefix() + "__input " + this.cssPrefix() + "__input--" + this.name() + " " + this.cssClass()
    )

    public labelKey = ko.pureComputed( () =>
        (this.i18nPrefix() ? this.i18nPrefix() + "." : "") + this.name() + ".label"
    );

    public placeholderKey  = ko.pureComputed( () =>
        (this.i18nPrefix() ? this.i18nPrefix() + "." : "") + this.name() + ".placeholder"
    );

    public cssClassLabel = ko.pureComputed( () =>
        this.cssPrefix() + "__label " + this.cssPrefix() + "__label--" + this.name()
    );
}

let component:KnockoutComponentTypes.Config = {
    viewModel: (params:TextInputViewModelParams, componentInfo?: KnockoutComponentTypes.ComponentInfo) => new TextInputViewModel(params),
    template: <string>require('./text-input.html')
}

export default component;