import * as ko from 'knockout'
import {NewsWidgetViewModel, NewsWidgetViewModelParams} from "./NewsWidgetViewModel";


const component: KnockoutComponentTypes.Config = {
    viewModel: (params: NewsWidgetViewModelParams) => new NewsWidgetViewModel(params),
    template: <string>require('./news-list-item.html')
};

export default component;

if (!ko.components.isRegistered('news-list-item')) {
    ko.components.register('news-list-item', component)
}