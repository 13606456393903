import * as ko from 'knockout'
import {CommentDto} from "../../api/generated";
import {autobind, observable} from "knockout-decorators";
import {commentApi} from "../../api/api-wrapper";
import {postbox} from "../util/postbox";
import globalState from '../../global-state';
import {FileUploadViewModel} from "../../utils/FileUploadViewModel";
import {ideaAttachmentsRegex} from "../../pages/ideas/ideaUtils";
import "../../components/elements/attachments/images-carousel"

interface ViewModelParams {
    comment: CommentDto;
    level: number;
    showIdeaInfo: boolean;
    showReplyList: boolean;
}

export class CommentForm {

    /**
     * The comment id.
     */
    public id: KnockoutObservable<number> = ko.observable(null);

    /**
     * The comment title.
     */
    public title: KnockoutObservable<string> = ko.observable('');

    /**
     * The comment.
     */
    public comment: KnockoutObservable<string> =
        ko.observable('').extend({required: true, maxLength: 65535});

    /**
     * Flag whether the form is visible.
     */
    public visible: KnockoutObservable<boolean> =
        ko.observable(false);

    /**
     * Toggle the visible state.
     */
    @autobind
    public toggleVisible() {
        if (this.visible()) {
            this.reset();
        }
        this.visible(!this.visible());
    }

    @autobind
    private reset() {
        this.id(null);
        this.title("");
        this.comment("");
    }
}

class ViewModel extends FileUploadViewModel {

    /**
     * The comment.
     */
    @observable({deep: true, expose: true})
    public comment: CommentDto;

    /**
     * The reply level of this comment to set the correct margin-left.
     */
    public level: number;

    /**
     * The next reply level.
     */
    public nextLevel: KnockoutComputed<number>;

    /**
     * The CSS class according to the reply level.
     */
    public commentCssClass: KnockoutComputed<string>;

    /**
     * Show the title of the idea on top of the comment.
     */
    public showIdeaInfo: KnockoutObservable<boolean>;

    /**
     * Show the reply list of the comment.
     */
    public showReplyList: KnockoutObservable<boolean>;

    /**
     * Show the reply list of the comment.
     */
    public isEditable: KnockoutComputed<boolean>;

    /**
     * The reply form.
     */
    public form: CommentForm;

    constructor(private params: ViewModelParams) {
        super();
        this.comment = this.params.comment;
        this.level = this.params.level ? this.params.level : 0;
        this.form = new CommentForm();
        this.nextLevel = ko.pureComputed(() =>
            (this.level + 1)
        );
        this.commentCssClass = ko.pureComputed(() =>
            "comment-level-" + this.level
        );
        // noinspection RedundantConditionalExpressionJS
        this.showIdeaInfo = ko.observable(
            params.showIdeaInfo === true ? true : false
        );
        // noinspection RedundantConditionalExpressionJS
        this.showReplyList = ko.observable(
            params.showReplyList === false ? false : true
        );
        this.isEditable = ko.pureComputed(() =>
            globalState.user().admin || globalState.user().id == this.comment.user.id)
    }

    /**
     * Click handler to like a comment.
     */
    @autobind
    public like() {
        globalState.loading(true);
        commentApi.putCommentLikes(this.comment.id)
            .then(value => {
                globalState.loading(false);
                if (value > this.comment.likesCnt) {
                    this.comment.likesCnt = value;

                } else {
                    postbox.addInfo('comments.info.likeExists');
                }

            })
            .catch(() => {
                globalState.loading(false);
                postbox.addError('comments.error.like');
            });
    }

    /**
     * Save a reply.
     */
    @autobind
    public saveReply() {
        const errors = ko.validation.group(this.form);
        if (errors().length > 0) {
            errors.showAllMessages();
            postbox.addError('validation.failed');
            return;
        } else {
            globalState.loading(true);
            const comment: CommentDto = {
                title: this.form.title(),
                comment: this.form.comment(),
                timestamp: new Date(),

            }
            return this.filesPromise(ideaAttachmentsRegex)
                .then(uploads =>
                    comment.uploads = uploads)
                .then(() =>
                    commentApi.postCommentReply(this.comment.id, comment))
                .then(commentDto => {
                    this.comment.replyList.push(commentDto);
                    this.form.toggleVisible();
                    this.resetFilesData();
                })
                .catch(reason => {
                    console.error(reason);
                    postbox.addError('comment.error.saveReply');
                })
                .finally(() =>
                    globalState.loading(false));
        }
    }

    /**
     * Edit and save an existing comment.
     */
    @autobind
    public saveComment() {
        const errors = ko.validation.group(this.form);
        if (errors().length > 0) {
            errors.showAllMessages();
            postbox.addError('validation.failed');
            return;
        } else {
            globalState.loading(true);
            this.comment.title = this.form.title();
            this.comment.comment = this.form.comment();
            this.filesPromise(ideaAttachmentsRegex)
                .then(uploads =>
                    this.comment.uploads = uploads)
                .then(() =>
                    commentApi.putComment(this.comment.id, this.comment))
                .then(commentDto => {
                    this.comment = commentDto;
                    this.form.toggleVisible();
                    this.resetFilesData();
                })
                .catch(reason => {
                    console.error(reason);
                    postbox.addError('comment.error.saveComment');
                })
                .finally(() =>
                    globalState.loading(false));
        }
    }

    /**
     * Show the form to edit a comment.
     */
    public editComment() {
        this.form.id(this.comment.id);
        this.form.title(this.comment.title);
        this.form.comment(this.comment.comment);
        this.form.toggleVisible();
    }
}


const component: KnockoutComponentTypes.Config = {
    viewModel: (params: ViewModelParams) => new ViewModel(params),
    template: <string>require('./comment.html')
};

export default component;

if (!ko.components.isRegistered('comment')) {
    ko.components.register('comment', component)
}
