import * as ko from 'knockout'

interface ViewModelParams {
    dateFromFilter: KnockoutObservable<Date>;
    dateToFilter: KnockoutObservable<Date>;
}

class ViewModel {

    /**
     * The date from filter.
     */
    public dateFromFilter: KnockoutObservable<Date>;

    /**
     * The date to filter.
     */
    public dateToFilter: KnockoutObservable<Date>;

    /**
     * Constructor.
     *
     * @param params
     */
    constructor(private params: ViewModelParams) {
        this.dateFromFilter = this.params.dateFromFilter;
        this.dateToFilter = this.params.dateToFilter;
    }

}

const component: KnockoutComponentTypes.Config = {
    viewModel: (params: ViewModelParams) => new ViewModel(params),
    template: <string>require('./report-filters.html')
};

export default component;

if (!ko.components.isRegistered('report-filters')) {
    ko.components.register('report-filters', component)
}