import * as ko from 'knockout'
import ensureObservable from "../../util/ensure-observable";

interface ViewModelParams {
    label: string | KnockoutObservable<string>;
    total: number | KnockoutObservable<number>;
    value: number | KnockoutObservable<number>;
    cssClass: string | KnockoutObservable<string>
}

class ViewModel {

    /**
     * The label.
     */
    label: KnockoutObservable<string>;

    /**
     * Total amount.
     */
    public total: KnockoutObservable<number>;

    /**
     * Current value.
     * Might be an observable which get's updated by the parent view model using setInterval.
     */
    public value: KnockoutObservable<number>;

    /**
     * Current percentage value.
     */
    public percentage: KnockoutComputed<number>;

    /**
     * Current percentage css class,
     */
    public percentageCssClass: KnockoutComputed<string>;

    /**
     * The user image css class.
     */
    public cssClass: KnockoutObservable<string>

    /**
     * Constructor.
     *
     * @param params
     */
    constructor(private params:ViewModelParams) {
        this.label = ensureObservable(params.label);
        this.total = ensureObservable(params.total);
        this.value = ensureObservable(params.value);
        this.percentage = ko.pureComputed(() => {
            const percentage = Math.round(100 - (100 / this.total() * this.value()));
            return percentage > 0 ? percentage : 0;
        });
        this.percentageCssClass = ko.pureComputed(() => this.cssClass() + " p" + this.percentage());
        this.cssClass = ensureObservable(params.cssClass ? params.cssClass : "small");
    }
}

const component:KnockoutComponentTypes.Config = {
    viewModel: (params:ViewModelParams) => new ViewModel(params),
    template: <string>require('./countdown.html')
};

export default component;

if (!ko.components.isRegistered('countdown')) {
    ko.components.register('countdown', component)
}