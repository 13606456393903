import * as ko from 'knockout'
import {UserRankingDto, UserRankingsDto} from "../../../api/generated";
import ensureObservable from "../../util/ensure-observable";
import "./ranking-list-item"

interface ViewModelParams {
    title: string | KnockoutObservable<string>;
    rankings: UserRankingsDto | KnockoutObservable<UserRankingsDto>;
    cssClass: string;
    color: string;
}

class ViewModel {

    /**
     * Default column css class
     */
    private columsCssClassDefault: string = "mb-6 col-12 col-lg-6";

    /**
     * Default color for the component
     */
    private colorDefault: string = "primary";

    /**
     * The css class prefix for the color.
     */
    private colorClassPrefix: string = "card-rankings--";

    /**
     * The color - primary|secondary
     */
    public color: string;

    /**
     * The title.
     */
    public title: KnockoutObservable<string>;

    /**
     * The rankings.
     */
    public rankings: KnockoutObservable<UserRankingsDto>;

    /**
     * The top rankings.
     */
    public rankingsTop: KnockoutComputed<UserRankingDto[]>;

    /**
     * The nankings near to the current user
     */
    public rankingsNear: KnockoutComputed<UserRankingDto[]>;

    /**
     * The column css class.
     */
    private readonly cssClassColumn: string;

    /**
     * The color css class.
     */
    private readonly cssClassColor: string;

    /**
     * The css class.
     */
    public cssClass: string;

    /**
     * Constructor.
     *
     * @param params
     */
    constructor(private params: ViewModelParams) {
        this.rankings = ensureObservable(this.params.rankings);
        this.title = ensureObservable(params.title);
        this.cssClassColumn = params.cssClass || this.columsCssClassDefault;
        this.cssClassColor = params.color ? this.colorClassPrefix + params.color : this.colorClassPrefix + this.colorDefault;
        this.cssClass = this.cssClassColumn + " " + this.cssClassColor;
        this.rankingsTop = ko.pureComputed(() => this.rankings() != null ? this.rankings().top : []);
        this.rankingsNear = ko.pureComputed(() => this.rankings() != null ? this.rankings().near : []);
    }
}

const component: KnockoutComponentTypes.Config = {
    viewModel: (params: ViewModelParams) => new ViewModel(params),
    template: <string>require('./card-rankings.html')
};

export default component;

if (!ko.components.isRegistered('card-rankings')) {
    ko.components.register('card-rankings', component)
}