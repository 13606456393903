import {UserDto} from "../api/generated";
import * as ko from "knockout";
import {userApi} from "../api/api-wrapper";
import {autobind} from "knockout-decorators";
import {asyncComputed} from "knockout-async-computed/dist/knockout-async-computed";
import {App} from "../app";
import globalState from "../global-state";


export class UserSearchForm {

    /**
     * The selected user.
     */
    public user: KnockoutObservable<UserDto>;

    /**
     * The users for the autocomplete list.
     */
    public users: KnockoutObservable<UserDto[]>;

    /**
     * The user autocomplete query.
     */
    public userQuery: KnockoutObservable<string>;

    /**
     * The min length for the query.
     */
    public userQueryMinLength: number = 3;

    /**
     * Flag whether the parent viewmodel has a subscription when the user is selected.
     * In this case the user name should not be written in search input field.
     */
    public userHasSubscription: KnockoutObservable<boolean>;

    private isSelection = false;

    /**
     * Constructor.
     */
    constructor(excludeSelf?: boolean) {
        this.user = ko.observable(null);
        this.userHasSubscription = ko.observable(false)
        this.userQuery = ko.observable("")
            .extend({rateLimit: {timeout: 400, method: "notifyWhenChangesStop"}});
        this.users = asyncComputed(async () => {
            // Pass async function that returns Promise
            // the query term must have min 3 characters
            if (this.userQuery().length >= this.userQueryMinLength && !this.isSelection) {
                this.isSelection = false;
                this.user(null);

                return userApi.getUsers(this.userQuery())
                    .then(users => users.filter(user => excludeSelf !== true || user.id !== globalState.user().id))
            } else {
                this.isSelection = false;

                return Promise.resolve([]);
            }
        }, []);
    }

    /**
     * Select an user for the autocomplete.
     * Returns the user name to be displayed in the search field.
     * @param user
     */
    @autobind
    public selectUser(user: UserDto): string {
        if (user) {
            this.isSelection = true;
            this.user(user);

            if (!this.userHasSubscription()) {
                return this.formatUser(user);

            } else {
                return '';
            }

        } else {
            return "";
        }
    }

    /**
     * Format an user for the autocomplete.
     * @param user
     */
    public formatUser(user: UserDto) {
        const formattedName = App.username(user);
        if (formattedName === user.email) {
            return formattedName;
        } else {
            return `${formattedName} (${user.email})`;
        }
    }

    /**
     * Reset the selected user .
     */
    @autobind
    public resetUser() {
        this.user(null);
        this.userQuery("");
    }
}
