import * as ko from 'knockout'
import {CategoryDto, IdeaDto} from "../../../api/generated";
import {ideaAverageRatingFormatted, ideaMainImageStyle, ideaUserName} from "../../../pages/ideas/ideaUtils";
import globalState from '../../../global-state';
import {computed, observable} from "knockout-decorators";
import {categoryNameTranslated} from "../../../pages/admin/categoryUtils";
import {config} from "../../../utils/clientConfigWrapper";

interface ViewModelParams {
    idea: IdeaDto;
    stateVisible: boolean;
}

class ViewModel {

    /**
     * The idea.
     */
    @observable({deep: true, expose: false})
    public idea: IdeaDto;

    /**
     * Flag whether the idea's state should be visible.
     */
    public stateVisible: KnockoutObservable<boolean>;

    /**
     * Constructor.
     *
     * @param params
     */
    constructor(private params: ViewModelParams) {
        this.idea = this.params.idea;
        this.stateVisible = ko.observable(this.params.stateVisible === false ? false : true);
    }

    /**
     * Check whether the idea belongs to the authenticated user.
     */
    @computed
    public get isMyIdea() {
        return this.idea.user.id === globalState.user().id;
    }

    /**
     * The path for the details view.
     */
    @computed
    public get detailsPath() {
        return "/idee/" + this.idea.id;
    }

    /**
     * The path for the edit view.
     */
    @computed
    public get editPath() {
        return "/idee/" + this.idea.id + "/bearbeiten";
    }

    /**
     * Get the styles for the idea main image.
     */
    public ideaMainImageStyle(): string {
        return ideaMainImageStyle(this.idea);
    }

    /**
     * Get the category name or the translation if it exists.
     * @param category
     */
    public categoryName(category: CategoryDto) {
        return categoryNameTranslated(category);
    }

    /**
     * Is the likes count visible?
     */
    public showLikes() {
        return (globalState.appVars.showLikesInOverview || false);
    }

    public userRatingsEnabled() {
        return (config.userRatingsEnabled || false);
    }

    public averageRating() {
        return ideaAverageRatingFormatted(this.idea);
    }

    /**
     * Get the user name or the text for anonymous users.
     */
    public ideaUserName(): KnockoutComputed<string> {
        return ideaUserName(this.idea.user, this.idea.anonymous);
    }
}

const component: KnockoutComponentTypes.Config = {
    viewModel: (params: ViewModelParams) => new ViewModel(params),
    template: <string>require('./idea-list-item.html')
};

export default component;

if (!ko.components.isRegistered('idea-list-item')) {
    ko.components.register('idea-list-item', component)
}
