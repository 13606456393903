import {WidgetDto} from "../../../api/generated";
import * as ko from "knockout";
import {WidgetContentNews} from "../../../pages/editor/_common";
import {config} from "../../../utils/clientConfigWrapper";

export interface NewsWidgetViewModelParams {
    newsWidget: WidgetDto;
    cssClass: string;
}

export class NewsWidgetViewModel {

    private maxHeadlineLength: number = 45;
    private maxTeaserLength: number = 160;

    /**
     * Default css class for a maximum 4 column layout.
     * Example for max 3 col layout: col-12 col-sm-6 col-xl-4
     */
    private cssClassDefault: string = "col-12 col-sm-6 col-xl-4";

    /**
     * The news content.
     */
    public newsWidget: WidgetDto;

    /**
     * The css class.
     */
    public cssClass: KnockoutObservable<string>;

    /**
     * Constructor.
     *
     * @param params
     */
    constructor(private params: NewsWidgetViewModelParams) {
        this.newsWidget = params.newsWidget;
        this.cssClass = ko.observable(params.cssClass || params.cssClass == '' ? params.cssClass : this.cssClassDefault);
    }

    public get headline(): string {
        return this.trim(this.newsWidget.content.headline, this.maxHeadlineLength);
    }

    public get teaserText(): string {
        return this.trim(this.newsWidget.content.teaserText, this.maxTeaserLength);
    }

    public get detailsPath(): string {
        return "/news/" + this.newsWidget.id;
    }

    public get content(): WidgetContentNews {
        return <WidgetContentNews>this.newsWidget.content;
    }

    public get imageStyle(): string {
        return this.content.teaserImageRef ?
            `background-image: url('${config.attachmentEndpoint}${this.content.teaserImageRef}');` : "";
    }

    public get publishDate(): Date {
        return this.content.publishDate || new Date();
    }

    /**
     * Trim a text to a max length.
     *
     * @param text
     * @param maxLength
     */
    private trim(text: string, maxLength: number): string {
        if (!text) {
            return null;
        }
        const suffix = text.length > maxLength ? "..." : "";
        return text.substring(0, maxLength) + suffix;
    }
}