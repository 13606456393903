import * as ko from "knockout";
import {CampaignWidgetViewModel, CampaignWidgetViewModelParams} from "./CampaignWidgetViewModel";

const component: KnockoutComponentTypes.Config = {
    viewModel: (params: CampaignWidgetViewModelParams) => new CampaignWidgetViewModel(params),
    template: <string>require('./active-campaign-item.html')
};

export default component;

if (!ko.components.isRegistered('active-campaign-item')) {
    ko.components.register('active-campaign-item', component)
}