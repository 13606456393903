import {Campaign, CampaignDto} from "../../../api/generated";
import * as ko from "knockout";
import i18nextko from "../../../bindings/i18nko";
import {computed, observable} from "knockout-decorators";
import {isFuture} from "../../../pages/campaigns/campaignUtils";
import {config} from "../../../utils/clientConfigWrapper";
import moment = require("moment");

export interface CampaignWidgetViewModelParams {
    campaign: CampaignDto;
    cssClass: string;
}

export class CampaignWidgetViewModel {

    private maxHeadlineLength: number = 60;
    private maxTeaserLength: number = 160;

    /**
     * Default css class for a maximum 4 column layout.
     * Example for max 3 col layout: col-12 col-sm-6 col-xl-4
     */
    private cssClassDefault: string = "col-12 col-sm-6 col-xl-4";

    /**
     * The news content.
     */
    @observable
    public campaign: Campaign;

    /**
     * The css class.
     */
    public cssClass: KnockoutObservable<string>;

    /**
     * Constructor.
     *
     * @param params
     */
    constructor(private params: CampaignWidgetViewModelParams) {
        this.campaign = params.campaign;
        this.cssClass = ko.observable(params.cssClass || params.cssClass == '' ? params.cssClass : this.cssClassDefault);
    }

    public get campaignEnd() {
        return this.campaign.end ?
            (moment(this.campaign.end).isSameOrAfter(moment(), 'day') ?
                i18nextko.t('widget.campaign.willEnd', {
                    'end': moment(this.campaign.end).fromNow()
                }) :
                i18nextko.t('widget.campaign.endedSince', {
                    'since': moment(this.campaign.end).fromNow()
                })) :
            i18nextko.t('widget.campaign.ended');
    }

    @computed
    public get headline(): string {
        return this.trim(this.campaign.title, this.maxHeadlineLength);
    }

    @computed
    public get teaserText(): string {
        return this.trim(this.campaign.description, this.maxTeaserLength);
    }

    @computed
    public get detailsPath(): string {
        return "/kampagne/" + this.campaign.id;
    }

    @computed
    public get imageStyle(): string {
        return this.campaign.sliderImage ?
            `background-image: url('${config.attachmentEndpoint}${config.campaignsUploadPath}/${this.campaign.sliderImage}');` :
            "";
    }

    @computed
    public get teaserImageStyle(): string {
        return this.campaign.image ?
            `background-image: url('${config.attachmentEndpoint}${config.campaignsUploadPath}/${this.campaign.image}');` :
            this.imageStyle;
    }

    @computed
    public get ideasCount(): number {
        return this.campaign.ideaList.length;
    }

    @computed
    public get futureCampaign(): boolean {
        return isFuture(this.campaign);
    }


    /**
     * Trim a text to a max length.
     *
     * @param text
     * @param maxLength
     */
    private trim(text: string, maxLength: number): string {
        if (!text) {
            return null;
        }
        const suffix = text.length > maxLength ? "..." : "";
        return text.substring(0, maxLength) + suffix;
    }
}
