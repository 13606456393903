import * as ko from 'knockout'
import ensureObservable from "../util/ensure-observable";
import globalState from '../../global-state';

interface ViewModelParams {
    text: string | KnockoutObservable<string>;
}

class ViewModel {

    /**
     * The text to check.
     */
    public text: KnockoutObservable<string>;

    /**
     * The indicator css class according to the text length.
     */
    public cssClass: KnockoutComputed<string>;

    /**
     * The indicator i18n key according to the text length.
     */
    public i18nKey: KnockoutComputed<string>;

    /**
     * Indicator suffix
     */
    private indicatorKey: KnockoutComputed<string>;

    constructor(private params: ViewModelParams) {

        this.text = ensureObservable(this.params.text);
        this.cssClass = ko.pureComputed(() => "text-length-indicator__color--" + this.indicatorKey());
        this.i18nKey = ko.pureComputed(() => "idea.create.characters." + this.indicatorKey());
        this.indicatorKey = ko.pureComputed(() => {
            if (!this.text() || this.text().length <= (globalState.appVars.textLengthIndicators.small || 200)) {
                return "small";
            } else if (this.text().length <= (globalState.appVars.textLengthIndicators.medium || 500)) {
                return "medium";
            } else {
                return "large";
            }
        });

    }
}

const component: KnockoutComponentTypes.Config = {
    viewModel: (params: ViewModelParams) => new ViewModel(params),
    template: <string>require('./text-length-indicator.html')
};

export default component;

if (!ko.components.isRegistered('text-length-indicator')) {
    ko.components.register('text-length-indicator', component)
}