import * as ko from "knockout";
import {EvaluationDto} from "../../../api/generated";
import {autobind, observable} from "knockout-decorators";
import ensureObservable from "../../util/ensure-observable";

export interface ViewModelParams {
    evaluation: EvaluationDto;
    stateCssClass: string;
}


class ViewModel {

    /**
     * The idea.
     */
    @observable({deep: false, expose: false})
    public evaluation: EvaluationDto;

    /**
     * The ideas state css class.
     */
    public stateCssClass: KnockoutObservable<string>;

    /**
     * The path for the details view.
     */
    public detailsPath: KnockoutComputed<string>;

    /**
     * Constructor.
     *
     * @param params
     */
    constructor(private params: ViewModelParams) {
        this.evaluation = this.params.evaluation;
        this.stateCssClass = ensureObservable(params.stateCssClass ? params.stateCssClass : "");
        this.detailsPath = ko.pureComputed(() => "/gutachten/" + this.evaluation.id + '/bearbeiten');
    }

    @autobind
    public hasSubmittedTimestamp() {
        return this.evaluation.submittedTimestamp != null;
    }
}


const component:KnockoutComponentTypes.Config = {
    viewModel: (params:ViewModelParams) => new ViewModel(params),
    template: <string>require('./dashboard-list-evaluation.html')
};

export default component;

if (!ko.components.isRegistered('dashboard-list-evaluation')) {
    ko.components.register('dashboard-list-evaluation', component)
}