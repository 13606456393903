import * as ko from "knockout";
import {IdeaListItemViewModel, IdeaListItemViewModelParams} from "./IdeaListItemViewModel";


const component:KnockoutComponentTypes.Config = {
    viewModel: (params:IdeaListItemViewModelParams) => new IdeaListItemViewModel(params),
    template: <string>require('./dashboard-list-idea.html')
};

export default component;

if (!ko.components.isRegistered('dashboard-list-idea')) {
    ko.components.register('dashboard-list-idea', component)
}