import * as ko from 'knockout'
import {UserRankingDto} from "../../../api/generated";
import globalState from "../../../global-state";
import {config} from "../../../utils/clientConfigWrapper";

interface ViewModelParams {
    userRanking: UserRankingDto;
    index: number;
}

class ViewModel {

    /**
     * The user ranking.
     */
    public userRanking: UserRankingDto;

    /**
     * The current index to calculate even|odd css classes.
     */
    public index: number;

    /**
     * Constructor.
     *
     * @param params
     */
    constructor(private params: ViewModelParams) {
        this.userRanking = this.params.userRanking;
        this.index = params.index || 0;
    }

    /**
     * Check if it's the list entry of the authenticated user.
     */
    public isMyListEntry() {
        return globalState.user().id === this.userRanking.userId
    }

    /**
     * Even or odd row?
     */
    public isOdd() {
        return this.index % 2 === 0;
    }

    public imageStyle() {
        if (this.userRanking.image && this.userRanking.image.length > 0) {
            return `background-image: url('${config.attachmentEndpoint}${this.userRanking.image}'), url('/${config.publicPath}/images/dummy.png');`;
        }
        return "";
    }
}

const component: KnockoutComponentTypes.Config = {
    viewModel: (params: ViewModelParams) => new ViewModel(params),
    template: <string>require('./ranking-list-item.html')
};

export default component;

if (!ko.components.isRegistered('ranking-list-item')) {
    ko.components.register('ranking-list-item', component)
}