import * as ko from "knockout";
import ensureObservable from "../util/ensure-observable";
import {autobind, observable} from "knockout-decorators";

interface ViewModelParams {
    score: KnockoutObservable<number>;
    min: number | KnockoutObservable<number>;
    max: number | KnockoutObservable<number>;
    cssClass: string | KnockoutObservable<string>;
    allowUnset: boolean | KnockoutObservable<boolean>;
    editable: boolean | KnockoutObservable<boolean>;
    handleRate: (vm: ViewModel) => void;
    confirmRate: boolean
}

export class ViewModel {

    /**
     * Default min rating value.
     */
    private defaultMin: number = 1;

    /**
     * Default max rating value.
     */
    private defaultMax: number = 4;

    /**
     * The selected rating score from the parent view model.
     * Must be an observable to pass the selected value to the parent view model.
     */
    public score: KnockoutObservable<number>;

    /**
     * The selected score (if the rating has to be confirmed).
     * If it doesn't have to be confirmed it's equal to the score property.
     * If the score has to be confirmed it has to change in the UI, but the changed value should not be passed to the
     * parent view model before confirmation.
     */
    public selectedScore: KnockoutObservable<number>;

    /**
     * The minimum score.
     */
    public minScore: KnockoutObservable<number>;

    /**
     * The maximum score.
     */
    public maxScore: KnockoutObservable<number>;

    /**
     * The rating options.
     */
    public ratingOptions: KnockoutComputed<number[]>

    /**
     * Additional css class for the rating container
     */
    public cssClass:  KnockoutObservable<string>;

    /**
     * Flag whether the score can be unset.
     */
    public allowUnset: KnockoutObservable<boolean>;

    /**
     * Flag whether the rating is editable
     */
    public editable: KnockoutObservable<boolean>;

    public handleRate: (vm: ViewModel) => void;

    /**
     * Flag if the handleRate callback is executed immediately or after confirmation.
     */
    public confirmHandleRate: boolean;

    /**
     * Flag if the confirm button is visible.
     */
    @observable
    public isConfirmBtnVisible: boolean;

    /**
     * Constructor.
     *
     * @param params
     */
    constructor(private params:ViewModelParams) {

        if(!ko.isObservable(params.score)) {
            throw new Error('Rating component: score param must be an observable');
        }

        this.score = params.score;
        this.confirmHandleRate = params.confirmRate && params.confirmRate === true;
        this.selectedScore = this.confirmHandleRate ? ko.observable(params.score()) : params.score;
        this.minScore = (params.min ? ensureObservable(params.min) : ko.observable(this.defaultMin));
        this.maxScore = (params.max ? ensureObservable(params.max) : ko.observable(this.defaultMax));
        this.cssClass =  (params.cssClass ? ensureObservable(params.cssClass) : ko.observable(""));
        this.ratingOptions = ko.computed(() => {
            let options: number[] = [];
            for (let i = this.minScore(); i <= this.maxScore(); i++) {
                options.push(i);
            }
            return options;
        });
        this.allowUnset = params.allowUnset === undefined ? ko.observable(true) : ensureObservable(params.allowUnset);
        this.editable = params.editable === undefined ? ko.observable(false) : ensureObservable(params.editable);
        this.handleRate = params.handleRate || function() {};

        this.isConfirmBtnVisible = false;
    }

    /**
     * Rate - set/reset the score.
     * If the new rating matches the existing score the score is reset to null.
     */
    @autobind
    public rate(rating: number) {
        if(this.editable()) {

            // selected rating is clicked again -> unset
            if(rating == this.selectedScore()) {

                if(this.allowUnset()) {
                    this.selectedScore(null);

                    // unset rating immediately or after confirmation
                    if(this.confirmHandleRate) {
                        console.debug("show rating unset confirmation:", this.selectedScore());
                        this.isConfirmBtnVisible = true;
                    } else {
                        console.debug("save rating unset without confirmation:", this.selectedScore());
                        this.handleRate(this);
                    }
                } else {
                    console.debug("rating - reset score is not allowed:", this.selectedScore());
                }
            } else {

                // handle new rating value
                this.selectedScore(rating);

                // rate immediately or after confirmation
                if(this.confirmHandleRate) {
                    console.debug("show rating confirmation:", this.selectedScore());
                    this.isConfirmBtnVisible = true;
                } else {
                    console.debug("save rating without confirmation:", this.selectedScore());
                    this.isConfirmBtnVisible = false;
                    this.handleRate(this);
                }
            }
        } else {
            console.debug("Rating is not editable");
        }
    }

    public confirm() {
        console.debug("save rating after confirmation:", this.selectedScore());
        this.score(this.selectedScore())
        this.handleRate(this);
        this.isConfirmBtnVisible = false;
    }

    /**
     * Check whether a rating is active and should be displayed as selected.
     * A rating is active if it is less or equal to the current score.
     * @param rating
     */
    @autobind
    public ratingActive(rating: number) {
        return ko.pureComputed(() =>
            this.selectedScore() != null && rating <= this.selectedScore()
        );
    }
}


const component:KnockoutComponentTypes.Config = {
    viewModel: (params:ViewModelParams) => new ViewModel(params),
    template: <string>require('./rating.html')
};

export default component;

if (!ko.components.isRegistered('rating')) {
    ko.components.register('rating', component)
}
