import * as ko from "knockout"

/**
 * Wraps the normal foreach binding and creates a computed on the fly that returns a structure with rows/columns
 * based on a "count" option.
 *
 * <div data-bind="foreachGroups: { data: items, count: count }">
 *     <ul data-bind="foreach: $data">
 *         <li data-bind="text: $data"></li>
 *     </ul>
 * </div>
 *
 * https://stackoverflow.com/questions/17591216/creating-groups-with-knockout-js-foreach
 */

ko.bindingHandlers.foreachGroups = {
    init: function(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        const options = valueAccessor();

        ko.utils.registerEventHandler(window, "resize", function() {
            //run your calculation logic here and update the "count" observable with a new value
        });

        //create our own computed that transforms the flat array into rows/columns
        const groupedItems = ko.computed({
            read: function() {
                let index, group,
                    result = [],
                    count = +ko.utils.unwrapObservable(options.count) || 1,
                    items = ko.utils.unwrapObservable(options.data);

                //create an array of arrays (rows/columns)
                for (index = 0; index < items.length; index++) {
                    if (index % count === 0) {
                        group = [];
                        result.push(group);
                    }
                    group.push(items[index]);
                }
                console.debug("foreach-groups:", result);
                return result;
            },
            disposeWhenNodeIsRemoved: element
        });

        //use the normal foreach binding with our new computed
        ko.applyBindingsToNode(element, { foreach: groupedItems }, bindingContext);

        //make sure that the children of this element are not bound
        return { controlsDescendantBindings: true };
    }
};