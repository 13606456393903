import * as ko from 'knockout'
import {UserDto} from "../../../api/generated";
import {observable} from "knockout-decorators";
import globalState from "../../../global-state";
import i18nextko from "../../../bindings/i18nko";
import {config} from "../../../utils/clientConfigWrapper";
import {App} from "../../../app";

interface ViewModelParams {
    user: UserDto;
    cssClass: string;
    anonymous?: boolean;
}

class ViewModel {

    /**
     * The user.
     */
    @observable({deep: false, expose: false})
    public user: UserDto;

    /**
     * The user image css styles.
     */
    public imageStyle: KnockoutComputed<string>;

    /**
     * The user image css class.
     */
    public cssClass: KnockoutObservable<string>

    /**
     * Flag whether the user should be anonymous.
     */
    public anonymous: KnockoutObservable<boolean>;

    /**
     * Constructor.
     *
     * @param params
     */
    constructor(private params: ViewModelParams) {
        this.user = this.params.user;
        this.cssClass = ko.observable(params.cssClass || "user-image--circle");

        this.anonymous = ko.observable(params.anonymous === true);
        this.imageStyle = ko.pureComputed(() => {
                if (!this.anonymous() && this.user.image && this.user.image.length > 0) {
                    const imageUrl = this.user.image.startsWith("http") ? this.user.image :
                        `${config.attachmentEndpoint}${this.user.image}`;
                    return `background-image: url('${imageUrl}'), url('/${config.publicPath}/images/dummy.png');`;
                }
                return "";
            }
        );
    }

    /**
     * Check whether the image should link to the profile page.
     */
    public showProfileLink() {
        return globalState.user().admin || (!this.anonymous() && this.user.active);
    }

    /**
     * Get the image title.
     */
    public imageTitle(): KnockoutComputed<string> {
        return this.anonymous() && !globalState.user().admin ? i18nextko.t('idea.anonymousUser')() : App.username(this.user)
    }

}

const component: KnockoutComponentTypes.Config = {
    viewModel: (params: ViewModelParams) => new ViewModel(params),
    template: <string>require('./user-image.html')
};

export default component;

if (!ko.components.isRegistered('user-image')) {
    ko.components.register('user-image', component)
}
