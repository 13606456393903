import * as ko from "knockout";
import {IdeaListItemViewModel, IdeaListItemViewModelParams} from "./IdeaListItemViewModel";


const component:KnockoutComponentTypes.Config = {
    viewModel: (params:IdeaListItemViewModelParams) => new IdeaListItemViewModel(params),
    template: <string>require('./evaluation-idea.html')
};

export default component;

if (!ko.components.isRegistered('evaluation-idea')) {
    ko.components.register('evaluation-idea', component)
}