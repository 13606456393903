/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import * as ko from "knockout";
import ensureObservable from "../util/ensure-observable"



interface TextAreaViewModelParams {
  value: any;
  rows: number | KnockoutObservable<number>;
  name: string | KnockoutObservable<string>;
  cssClass: string | KnockoutObservable<string>;
  cssPrefix: string | KnockoutObservable<string>;
  i18nPrefix: string | KnockoutObservable<string>;
  showLabel: boolean | KnockoutObservable<boolean>;
}


class TextAreaViewModel
{
  value: KnockoutObservable<string>;
  name: KnockoutObservable<string>;
  cssClass: KnockoutObservable<string>;
  cssPrefix: KnockoutObservable<string>;
  i18nPrefix:  KnockoutObservable<string>;
  rows: KnockoutObservable<number>;
  showLabel: KnockoutObservable<boolean>;
  
  constructor(private params:TextAreaViewModelParams) {
    this.value = ensureObservable(params.value);
    this.name = ensureObservable(params.name);
    this.cssClass = ensureObservable(params.cssClass || "");
    this.cssPrefix = ensureObservable(params.cssPrefix || "form");
    this.i18nPrefix = ensureObservable(params.i18nPrefix);
    this.rows = ensureObservable(params.rows || 8);
    this.showLabel = ensureObservable(params.showLabel == false ? false : true);
  }  
  
  public cssClassField = ko.pureComputed(
      () => "form-control " + this.cssPrefix() + "__textarea " + this.cssPrefix() + "__textarea--" + this.name() + " " + this.cssClass()
  );
  public cssClassLabel = ko.pureComputed(
      ()=> this.cssPrefix() + "__label " + this.cssPrefix() + "__label--" + this.name()
  );
  public cssClassValidation = ko.pureComputed(
      () => "validationMessage " + this.cssPrefix() + "__validationMessage " + this.cssPrefix() + "__validationMessage--" + this.name()
  );  
  public labelKey = ko.pureComputed(
    () => (this.i18nPrefix() ? this.i18nPrefix() + "." : "") + this.name() + ".label"
  );

  public placeholderKey  = ko.pureComputed( () =>
      (this.i18nPrefix() ? this.i18nPrefix() + "." : "") + this.name() + ".placeholder"
  );
}

let component:KnockoutComponentTypes.Config = {
  viewModel: (params:TextAreaViewModelParams, componentInfo?: KnockoutComponentTypes.ComponentInfo) => new TextAreaViewModel(params),
  template: <string>require('./text-area.html')
}


export default component;
