import * as ko from 'knockout'
import {AttachmentDto} from "../../../api/generated";
import {autobind} from "knockout-decorators";
import {attachmentImages, attachmentImageStyle} from "./attachmentUtils";

interface ViewModelParams {
    attachmentList: KnockoutComputed<AttachmentDto[]>;
}

class ViewModel {

    /**
     * The complete attachment list.
     */
    public attachmentList: KnockoutComputed<AttachmentDto[]>;

    /**
     * The idea images.
     */
    public images: KnockoutComputed<AttachmentDto[]>;

    /**
     * Constructor.
     *
     * @param params
     */
    constructor(private params:ViewModelParams) {
        this.attachmentList = params.attachmentList;
        this.images = attachmentImages(this.attachmentList);
    }

    /**
     * Get the style for an idea image.
     *
     * @param attachment
     */
    public attachmentImageStyle(attachment: AttachmentDto): KnockoutComputed<string> {
        return attachmentImageStyle(attachment);
    }

    /**
     * Deletes an attachment
     * @param attachment
     */
    @autobind
    public deleteAttachment(attachment: AttachmentDto) {
        this.attachmentList().splice(this.attachmentList().indexOf(attachment), 1);
    }

}

const component:KnockoutComponentTypes.Config = {
    viewModel: (params:ViewModelParams) => new ViewModel(params),
    template: <string>require('./images.html')
};

export default component;

if (!ko.components.isRegistered('images')) {
    ko.components.register('images', component)
}