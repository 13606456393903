import {CategoryDto, IdeaDto} from "../../../api/generated";
import {autobind, observable} from "knockout-decorators";
import * as ko from "knockout";
import {ideaAverageRatingFormatted, ideaMainImageStyle, ideaUserName} from "../../../pages/ideas/ideaUtils";
import ensureObservable from "../../util/ensure-observable";
import {categoryNameTranslated} from "../../../pages/admin/categoryUtils";
import {config} from "../../../utils/clientConfigWrapper";


export interface IdeaListItemViewModelParams {
    idea: IdeaDto;
    stateCssClass: string;
}


export class IdeaListItemViewModel {

    /**
     * The idea.
     */
    @observable({deep: false, expose: false})
    public idea: IdeaDto;

    /**
     * The ideas state css class.
     */
    public stateCssClass: KnockoutObservable<string>;

    /**
     * The path for the details view.
     */
    public detailsPath: KnockoutComputed<string>;

    /**
     * Is the likes count visible?
     */
    public showLikes: KnockoutComputed<boolean>;

    /**
     * Is the ratings count visible?
     */
    public showRatings: KnockoutComputed<boolean>;


    /**
     * Constructor.
     *
     * @param params
     */
    constructor(private params: IdeaListItemViewModelParams) {
        this.idea = this.params.idea;
        this.stateCssClass = ensureObservable(params.stateCssClass ? params.stateCssClass : "");
        this.detailsPath = ko.pureComputed(() => "/idee/" + this.idea.id);
        this.showRatings = ko.pureComputed(() => this.idea.ratingsCnt > 0);
        this.showLikes = ko.pureComputed(() => this.idea.likesCnt > 0);
    }

    /**
     * Get the styles for the idea main image.
     * @param attachments
     */
    @autobind
    public ideaMainImageStyle(): string {
        return ideaMainImageStyle(this.idea);
    }

    /**
     * Get the user name or the text for anonymous users.
     */
    @autobind
    public ideaUserName(): KnockoutComputed<string> {
        return ideaUserName(this.idea.user, this.idea.anonymous);
    }

    /**
     * Get the category name or the translation if it exists.
     * @param category
     */
    public categoryName(category: CategoryDto) {
        return categoryNameTranslated(category);
    }

    @autobind
    public averageRating() {
        return ideaAverageRatingFormatted(this.idea);
    }

}
