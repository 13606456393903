import * as ko from 'knockout'
import {UserDto} from "../../../api/generated";

interface ViewModelParams {
    user: UserDto;
    cssClass: string;
}

class ViewModel {

    /**
     * The user.
     */
    public user: UserDto;

    /**
     * The css class.
     */
    public cssClass: KnockoutObservable<string>;

    /**
     * Constructor.
     *
     * @param params
     */
    constructor(private params: ViewModelParams) {
        this.user = this.params.user;
        this.cssClass = ko.observable(params.cssClass || "");
    }
}

const component: KnockoutComponentTypes.Config = {
    viewModel: (params: ViewModelParams) => new ViewModel(params),
    template: <string>require('./user-list-item.html')
};

export default component;

if (!ko.components.isRegistered('user-list-item')) {
    ko.components.register('user-list-item', component)
}