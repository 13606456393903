import * as ko from 'knockout'
import {ExtraProfileDto, UserDto} from "../../../api/generated";
import {autobind, observable, unwrap} from "knockout-decorators";
import {postbox} from "../../util/postbox";
import {userApi} from "../../../api/api-wrapper";
import globalState from "../../../global-state";
import {Router} from "@profiscience/knockout-contrib-router";
import {departmentOptions, positionOptions} from "./userUtil";
import {config} from "../../../utils/clientConfigWrapper";

interface ViewModelParams {
    user: UserDto;
    successCallback?: any;
    cancelCallBack?: any;
    isRegistration?: boolean;
}

class ViewModel {

    /**
     * The user to edit.
     */
    @observable({ deep: true, expose: true })
    public user: UserDto;

    public successCallback: any;
    public cancelCallBack: any;

    public termsAccepted: KnockoutObservable<boolean>;
    public consentAccepted: KnockoutObservable<boolean>;

    /**
     * Constructor.
     *
     * @param params
     */
    constructor(private params:ViewModelParams) {

        this.user = Object.assign({}, this.ensureEditableFields(), params.user);

        this.successCallback = params.successCallback || null;
        this.cancelCallBack = params.cancelCallBack || null;
        this.termsAccepted = ko.observable(false);
        this.consentAccepted = ko.observable(false);

        unwrap(this.user, "prename").extend({required: true, maxlength: 255});
        unwrap(this.user, "surname").extend({required: true, maxlength: 255});
        unwrap(this.user, "telephone").extend({required: false, maxlength: 255});

        if (window.clientConfig.extraProfileEnabled) {
            unwrap(this.user, "department").extend({required: true, maxlength: 255});
            unwrap(this.user.extraProfile, "position").extend({required: true, maxlength: 255});
        }
    }

    @autobind
    public save() {
        const errors = ko.validation.group(this.user)
        if (errors().length > 0) {
            errors.showAllMessages();
            postbox.addError('validation.failed');
            return;
        }

        if (this.termsEnabled() && !this.termsAccepted()) {
            postbox.addError('global.error.termsAccepted');
            return;
        }
        if (this.consentEnabled() && !this.consentAccepted()) {
            postbox.addError('global.error.consentAccepted');
            return;
        }

        globalState.loading(true);
        this.user.registrationCompleted = true;
        return userApi.putUser(this.user.id, this.user).then(user => {
            this.user = user;
            // if the currently logged in user was updated - refresh the user in global state.
            if(globalState.user().id == user.id) {
                globalState.user(user);
            }
            globalState.loading(false);
            postbox.addInfo("profile.edit.success.save");
            return this.successCallback ? this.successCallback() : Router.update(`/profile/${this.user.id}`, {
                    push: true,
                    force: false
                });
        }).catch(reason => {
            globalState.loading(false);
            postbox.addError("profile.edit.error.save");
        }).finally(() =>
                globalState.loading(false));
    }

    public departmentOptions() {
        return departmentOptions();
    }

    public positionOptions() {
        return positionOptions();
    }

    /**
     * Ensure all editable fields of tue user exist on initialisation,
     * otherwise they will not be observables.
     * @private
     */
    private ensureEditableFields(): UserDto {
        const userEditable = {
            prename: '',
            surname: '',
            department: '',
            telephone: '',
            registrationCompleted: false
        } as UserDto;

        if(window.clientConfig.extraProfileEnabled) {
            userEditable.extraProfile = this.createExtraProfile();
        }

        return userEditable;
    }

    private createExtraProfile(): ExtraProfileDto {
        return {
            position: '',
            relation: '',
            employment: ''
        };
    }

    private defaultSuccessCallback(): Promise<boolean> {
        return Router.update(`/app/`, {
            push: true,
            force: true
        });
    }

    public terms(): string {
        return config.terms;
    }

    public termsEnabled(): boolean {
        return this.params.isRegistration && !config.termsAlways && typeof config.terms !== 'undefined';
    }

    public consent(): string {
        return config.consent;
    }

    public consentEnabled(): boolean {
        return this.params.isRegistration && typeof config.consent !== 'undefined';
    }
}

const component:KnockoutComponentTypes.Config = {
    viewModel: (params:ViewModelParams) => new ViewModel(params),
    template: <string>require('./edit-user.html')
};

export default component;

if (!ko.components.isRegistered('edit-user')) {
    ko.components.register('edit-user', component)
}
