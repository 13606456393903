import * as ko from "knockout";
import {UserNotificationDto} from "../../../api/generated";
import i18nextko from "../../../bindings/i18nko";
import {autobind} from "knockout-decorators";
import {config} from "../../../utils/clientConfigWrapper";
import moment = require("moment");
import globalState from "../../../global-state";

interface Link {
    text: string;
    id: number;
}

interface UserNotificationDetails {
    userLink?: Link;
    ideaLink?: Link;
    evaluationLink?: Link;
    awardLink?: Link;
    comment?: string;
    award?: string;
    level?: string;
    postponeDate?: string;
}

export interface UserNotificationViewModelParams {
    notification: UserNotificationDto;
    dismiss: Function;
}

export class UserNotificationViewModel {

    public notification: UserNotificationDto;
    public dismiss: Function;

    public modalVisible: KnockoutObservable<boolean>;
    public modalHeader: KnockoutComputed<string>;
    public modalContent: KnockoutComputed<string>;

    constructor(params: UserNotificationViewModelParams) {
        this.notification = params.notification;
        this.dismiss = params.dismiss;

        this.modalVisible = ko.observable(false);
        this.modalHeader = ko.pureComputed(() => {
            if (this.notification.type === UserNotificationDto.TypeEnum.AWARD) {
                return i18nextko.t("profile.award.modal." +
                    this.notification.details.awardLink.text + "." +
                    this.notification.details.userAwardLevel.toLowerCase() + ".title"
                )();
            } else {
                return "";
            }
        });
        this.modalContent = ko.pureComputed(() => {
            if (this.notification.type === UserNotificationDto.TypeEnum.AWARD) {
                return i18nextko.t("profile.award.modal." +
                    this.notification.details.awardLink.text + "." +
                    this.notification.details.userAwardLevel.toLowerCase() + ".text"
                )();
            } else {
                return "";
            }
        });
    }

    public get type() {
        return this.notification.type;
    }

    public get comment() {
        return this.details.comment;
    }

    public get details(): UserNotificationDetails {
        return this.notification.details;
    }

    public get since() {
        return moment(this.notification.created).fromNow();
    }

    public get postponeDate() {
        return moment(this.details.postponeDate).format('DD.MM.YYYY')
    }

    public get publicPath() {
        return config.publicPath;
    }

    public get userHref() {
        return `/${config.publicPath}/profile/${this.details.userLink.id}`;
    }

    public get ideaHref() {
        return `/${config.publicPath}/idee/${this.details.ideaLink.id}`;
    }

    public get evaluationHref() {
        return `/${config.publicPath}/gutachten/${this.details.evaluationLink.id}`;
    }

    public get awardHref() {
        return `/${config.publicPath}/profile/${this.details.awardLink.id}`;
    }

    public get evaluationEditHref() {
        return `/${config.publicPath}/gutachten/${this.details.evaluationLink.id}/erstellen`;
    }

    public get evaluationAssessmentHref() {
        return `/${config.publicPath}/gutachten/${this.details.evaluationLink.id}/bewerten`;
    }

    public get isUnread() {
        const lastRead = globalState.user().notificationsRead ?
            moment(globalState.user().notificationsRead) : null;
        return lastRead ? moment(this.notification.created).isAfter(lastRead) : true;
    }


    @autobind
    public showModal() {
        this.modalVisible(true);
    }

}

const component: KnockoutComponentTypes.Config = {
    viewModel: (params: UserNotificationViewModelParams) => new UserNotificationViewModel(params),
    template: <string>require('./user-notification-item.html')
};

export default component;

if (!ko.components.isRegistered('user-notification-item')) {
    ko.components.register('user-notification-item', component)
    console.debug('component user-notification-item registered');
}


