import * as ko from 'knockout'
import {UserDto} from "../../../api/generated";
import {autobind, observable} from "knockout-decorators";
import {departmentName} from "./userUtil";
import "knockout-sortable";
import {userApi} from "../../../api/api-wrapper";
import {postbox} from "../../util/postbox";

interface ViewModelParams {
    adminContacts: UserDto[];
    cssClass: string;
}

class ViewModel {

    /**
     * The user.
     */
    @observable({ deep: false, expose:false })
    public adminContacts: UserDto[];


    /**
     * The user image css class.
     */
    public cssClass: KnockoutObservable<string>

    /**
     * Constructor.
     *
     * @param params
     */
    constructor(private params:ViewModelParams) {
        this.adminContacts = this.params.adminContacts;
        this.cssClass = ko.observable(params.cssClass || "admin-contact");
    }

    public departmentName(user: UserDto) {
        return departmentName(user.department);
    }

    @autobind
    public saveSorting() {
        return userApi.sortAdminUsers(this.adminContacts)
            .then(adminContacts => this.adminContacts = adminContacts)
            .catch(reason => postbox.addError("admin.errors.sortAdmin"));
    }
}

const component:KnockoutComponentTypes.Config = {
    viewModel: (params:ViewModelParams) => new ViewModel(params),
    template: <string>require('./admin-contact.html')
};

export default component;

if (!ko.components.isRegistered('admin-contact')) {
    ko.components.register('admin-contact', component)
}
