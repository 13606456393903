import * as ko from 'knockout';
import i18nextko from '../../bindings/i18nko';

class Postbox {

    public errors: KnockoutObservableArray<string> = ko.observableArray([]);
    public infos: KnockoutObservableArray<string> = ko.observableArray([]);
    public successes: KnockoutObservableArray<string> = ko.observableArray([]);

    // noinspection JSUnusedLocalSymbols
    public addError = (key: string, options?: any, error?: any) =>
        this.errors.push(i18nextko.t(key, options).peek());

    public clearErrors = () =>
        this.errors.removeAll();

    // noinspection JSUnusedGlobalSymbols
    public addInfo = (key: string, options?: any) =>
        this.infos.push(i18nextko.t(key, options).peek());

    public clearInfos = () =>
        this.infos.removeAll();

    // noinspection JSUnusedGlobalSymbols
    public addSuccess = (key: string, options?: any) =>
        this.successes.push(i18nextko.t(key, options).peek());

    public clearSuccesses = () =>
        this.successes.removeAll();
}

export const postbox = new Postbox();