import * as ko from 'knockout'
import {CampaignWidgetViewModel, CampaignWidgetViewModelParams} from "./CampaignWidgetViewModel";

const component: KnockoutComponentTypes.Config = {
    viewModel: (params: CampaignWidgetViewModelParams) => new CampaignWidgetViewModel(params),
    template: <string>require('./campaign-list-item.html')
};

export default component;

if (!ko.components.isRegistered('campaign-list-item')) {
    ko.components.register('campaign-list-item', component)
}