import * as ko from "knockout";
import {AppVars, UserDto, UserNotificationDto} from "./api/generated";
import {observableArray} from "knockout-decorators";

/**
 * Global observables used trough out the application.
 */
export class GlobalState {
    /**
     * Triggers the loading indicator.
     */
    public loading: KnockoutObservable<boolean> = ko.observable(true);

    /**
     * Triggers scrollToError.
     */
    public hasErrors: KnockoutObservable<boolean> = ko.observable(false);

    /**
     * The authenticated user.
     */
    public user: KnockoutObservable<UserDto> = ko.observable();

    /**
     * Hide/Show the tutorial overlay
     */
    public tutorialEnabled: KnockoutObservable<boolean> = ko.observable(false);

    /**
     * Flag whether mails should be send. Can be switched off by the admin.
     */
    public sendMail: KnockoutObservable<boolean> = ko.observable(true);

    /**
     * Search filters for the ideas page should be remembered.
     */
    public ideasSearchFilters = {
        currentPage: ko.observable(1),
        searchTerm: ko.observable(""),
        ideaTypeFilter: ko.observable(""),
        ideaStateFilter: ko.observable(""),
        categoryFilter: ko.observable(""),
        userFilter: ko.observable(null),
        userQuery: ko.observable("").extend({rateLimit: {timeout: 400, method: "notifyWhenChangesStop"}}),
        dateFromFilter: ko.observable(null),
        dateToFilter: ko.observable(null),
        unPublishedFilter: ko.observable(false),
        campaignFilter: ko.observable(""),
        sortProperty:  ko.observable("statechanged")
    }

    /**
     * Search filters for the search page should be remembered.
     */
    public searchFilters = {
        // set rate limit because changes to this field trigger an api call
        searchTerm: ko.observable("")
            .extend({ rateLimit: { timeout: 400, method: "notifyWhenChangesStop" } })
    }

    /**
     * Search filters for the reports should be remembered.
     */
    public reportsSearchFilters = {
        dateFromFilter: ko.observable(null),
        dateToFilter: ko.observable(null)
    }

    /**
     * User notifications
     */
    @observableArray({expose: true})
    public notifications: UserNotificationDto[] = [];

    /**
     * Application Variables set by admin.
     */
    public appVars: AppVars;
}

/**
 * Exports a singleton state object.
 */
export default new GlobalState();
