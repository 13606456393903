import * as ko from "knockout";
import {autobind} from "knockout-decorators";
import {EvaluationDto} from "../../../api/generated";
import {evaluationStatusColor} from "../../../pages/evaluation/evaluationUtils";
import moment = require("moment");

interface ViewModelParams {
    items: KnockoutObservableArray<any>;
    headlineKey: string;
}

/**
 * Renders a card with list items and offers the option to show all items or a limited number.
 * The body of this component consists of HTML tags with bindings
 * or another component which is responsible to render each list item.
 */
class ViewModel {

    /**
     * The key for the headline of the card
     */
    headlineKey: string;

    /**
     * The list of items.
     */
    allItems: KnockoutObservableArray<any>;

    /**
     * The list of items to render (might be shorter than the original list)
     */
    itemsComputed: KnockoutComputed<any[]>;

    /**
     * Flag whther all items should be rendered
     */
    showAllItems: KnockoutObservable<boolean>;

    /**
     * The max number of items for the preview (short list)
     */
    maxPreviewItems: KnockoutObservable<number>;

    /**
     * Constructor.
     *
     * @param params
     */
    constructor(private params: ViewModelParams) {
        this.headlineKey = params.headlineKey;
        this.allItems = params.items;
        this.maxPreviewItems = ko.observable(4);
        this.showAllItems = ko.observable(false);
        this.itemsComputed = ko.pureComputed(() => {
            if(!this.showAllItems() && this.allItems().length > this.maxPreviewItems()) {
                return this.allItems().slice(0, this.maxPreviewItems());
            }
            return this.allItems();
        });
    }

    @autobind
    public toogleShowAllItems() {
        this.showAllItems(!this.showAllItems());
    }

    public stateCssClass(date: Date): string {
        const daysBetween = moment().diff(moment(date), 'days');
        if(daysBetween <= 7) {
            return "icon-state--green";
        } else if(daysBetween <= 14) {
            return "icon-state--yellow";
        }
        return "icon-state--red";
    }

    public evaluationSubmittedStateCssClass(evaluation: EvaluationDto) {
        const color: KnockoutComputed<string> = evaluationStatusColor(evaluation);
        return "icon-state--" + color();
    }

}

const component: KnockoutComponentTypes.Config = {
    viewModel: (params: ViewModelParams) => new ViewModel(params),
    template: <string>require('./card-dashboard-list.html')
};

export default component;

if (!ko.components.isRegistered('card-dashboard-list')) {
    ko.components.register('card-dashboard-list', component)
}