import * as ko from 'knockout'
import {AttachmentDto} from "../../../api/generated";
import {attachmentVideos} from "./attachmentUtils";
import {config} from "../../../utils/clientConfigWrapper";

interface ViewModelParams {
    attachmentList: KnockoutComputed<AttachmentDto[]>;
    headlineVisible: boolean;
}

class ViewModel {

    /**
     * The complete attachment list.
     */
    public attachmentList: KnockoutComputed<AttachmentDto[]>;

    /**
     * The documents from the attachment list.
     */
    public videos: KnockoutComputed<AttachmentDto[]>;

    /**
     * Show the headline
     */
    public headlineVisible: KnockoutObservable<boolean>;

    /**
     * Constructor.
     *
     * @param params
     */
    constructor(private params: ViewModelParams) {
        this.attachmentList = params.attachmentList;
        this.videos = attachmentVideos(this.attachmentList);
        this.headlineVisible = ko.observable(params.headlineVisible === true ? true : false);
    }

    /**
     * Get the document path.
     *
     * @param attachment
     */
    public videoPath(attachment: AttachmentDto): string {
        return `${config.attachmentEndpoint}${attachment.filename}`;
    }

    /**
     * Get the document name without the path.
     *
     * @param attachment
     */
    public videoName(attachment: AttachmentDto): string {
        return attachment.filename.substr(attachment.filename.lastIndexOf('/') + 1);
    }
}

const component: KnockoutComponentTypes.Config = {
    viewModel: (params: ViewModelParams) => new ViewModel(params),
    template: <string>require('./videos.html')
};

export default component;

if (!ko.components.isRegistered('videos')) {
    ko.components.register('videos', component)
}